<script lang="ts" setup>
import { twMerge } from 'tailwind-merge'

const { placeholder = 'Search...', innerClass } = defineProps<{
  placeholder?: string
  innerClass?: string
}>()
const modelValue = defineModel<string | null | undefined>()
const _innerClass = computed(() => {
  const defaultClass =
    'border-gray-800/20 !bg-gray-550/5 !mb-0 hover:!bg-gray-800'

  return twMerge(defaultClass, innerClass)
})
</script>
<template>
  <div
    class="flex flex-col items-center gap-2 border-b-2 border-gray-800 pb-4 md:flex-row"
  >
    <slot name="start" />
    <FormKit
      v-model="modelValue"
      type="search"
      name="search"
      :placeholder="placeholder"
      prefix-icon="search"
      prefix-icon-class="!bg-transparent to-transparent !border-0"
      outer-class="w-full mr-5"
      :inner-class="_innerClass"
    />
    <slot name="end" />
  </div>
</template>
